import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'ngx-sortablejs';
import { BreadcrumbModule } from 'angular-crumbs';
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { LoginService } from './services/LoginService';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import ptBr from '@angular/common/locales/pt';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CepPipe } from './pipes/cep.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { DataSharingService } from './data-sharing.service';
import { OrderByUpdatedAtDescPipe } from './pipes/OrderByUpdatedAtDescPipe.pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    CepPipe,
    PhonePipe
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    ToastrModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 }),
    HttpClientModule,
    Ng2SmartTableModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgHttpLoaderModule.forRoot()
  ],
  exports:[
    NgxMaskModule
  ],
  providers: [
    LoginService,
    { provide: LOCALE_ID, useValue: 'pt' },
    DataSharingService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
